<template>
  <div class="PERInformation" style="background-color: #fff">
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>

      <!-- 商户信息 -->
      <div class="to-box">
        <div class="left">
          <h2 style="text-align: left">商户信息</h2>
          <img
            :src="id.mechanism_logo"
            alt=""
            style="width: 100px; height: 100px; margin-top: 20px"
          />
          <p>商户门面</p>
        </div>

        <div class="flex dd1">
          <ul class="right">
            <li>
              商户名称: <span>{{ id.mechanism_name }}</span>
            </li>
            <li>
              联系电话: <span>{{ id.mechanism_telephone }}</span>
            </li>
            <li>
              经营类别: <span>{{ id.categories }}</span>
            </li>
          </ul>
          <ul class="right">
            <li>
              经营子类别: <span>{{ id.categories_child }}</span>
            </li>
            <li>
              商户地址: <span>{{ id.mechanism_addr }}</span>
            </li>
            <li>
              支付宝账号: <span>{{ id.payee_logon_id }}</span>
            </li>
          </ul>
          <ul class="right">
            <li>
              负责人姓名: <span>{{ id.contacts }}</span>
            </li>
            <li>
              负责人电话 :<span>{{ id.contact_information }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 添加表单弹窗 -->
      <!-- Form 资料修改表单-->
      <el-dialog
        class="dialog"
        title="地图"
        :visible.sync="dialogmap"
        :center="true"
      >
        <div>
          <mapdemo @getform="getmapdata2"></mapdemo>
        </div>
      </el-dialog>
      <!-- 商户营业执照 -->
      <ul class="picture">
        <li class="picture-li">
          <h2>商户营业执照</h2>
          <img
            :src="info.support_means"
            alt=""
            style="width: 100px; height: 100px"
          />
        </li>
        <li class="picture-li">
          <h2>商户门面</h2>
          <img
            :src="info.facade_view"
            alt=""
            style="width: 100px; height: 100px"
          />
        </li>
        <li class="picture-li">
          <h2>简介图片</h2>
          <div  class="flex" >
            <div v-for="(item, index) in piclist" :key="index">
            <img
              style="width: 100px; height: 100px"
              :src="item"
              alt=""
              class="p3"
            />
          </div>
          </div>
         
        </li>
      </ul>
      <el-tabs v-model="activeName" @tab-click="tabclick">
        <el-tab-pane label="商品" name="0">
          <el-form
            :inline="true"
            class="demo-form-inline"
            style="margin-top: 20px"
          >
            <el-form-item label="商品名称">
              <el-input v-model="Commodityquery" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="search">查询</el-button>
            </el-form-item>
          </el-form>
          <div style="display: flex; margin-bottom: 20px">
            <el-button type="warning" plain @click="dialogFormVisible = true"
              >添加商品</el-button
            >
          </div>
          <div class="ontents">
            <div class="topup-title">商品列表</div>
          </div>
          <div class="contentList">
            <div class="paging">
              <el-table
                border
                ref="multipleTable"
                :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
                :data="recording"
                tooltip-effect="dark"
                style="width: 100%"
                @filter-change="filterChange"
              >
                <el-table-column
                  prop="title"
                  align="center"
                  label="商品名称"
                ></el-table-column>
                <el-table-column
                  prop="create_time"
                  align="center"
                  label="创建时间"
                ></el-table-column>

                <el-table-column prop="status" label="状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" style="color: #23c3c4"
                      >待审核</span
                    >
                    <span
                      v-else-if="scope.row.status == 2"
                      style="color: #b8b8b8"
                      >已通过</span
                    >
                    <span
                      v-else-if="scope.row.status == 3"
                      style="color: #ff6262"
                      >已拒绝</span
                    >
                    <span
                      v-else-if="scope.row.status == 4"
                      style="color: #ff6262"
                      >注销</span
                    >
                    <span v-else style="color: #ff6262">冻结</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="categories"
                  align="center"
                  label="参加活动"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.commodity_type == 'A卡'"
                      @click="changeadd(scope.row)"
                    >
                      参加活动
                    </el-button>
                    <div v-else>无</div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.status == 2"
                      @click="UnUploaded(scope.row)"
                      type="text"
                      size="small"
                      >下架</el-button
                    >
                    <el-button
                      v-else
                      @click="Uploaded(scope.row)"
                      type="text"
                      size="small"
                      >上架</el-button
                    >

                    <el-button
                      @click="handleClick(scope.row, '课程')"
                      type="text"
                      size="small"
                      >修改</el-button
                    >
                    <el-button
                      @click="tuijian(scope.row)"
                      type="text"
                      size="small"
                      v-if="scope.row.is_recommend == false"
                      >推荐</el-button
                    >
                    <el-button
                      @click="Untuijian(scope.row)"
                      v-else
                      type="text"
                      size="small"
                      >取消推荐</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!--分页-->
              <div class="fyb">
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="订单" name="1">
          <div class="ontents">
            <div class="topup-title">订单列表</div>
          </div>
          <div class="contentList">
            <div class="paging">
              <el-table
                border
                ref="multipleTable"
                :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
                :data="orderList"
                tooltip-effect="dark"
                style="width: 100%"
                @filter-change="filterChange"
              >
                <el-table-column
                  prop="title"
                  align="categories"
                  label="商品名称"
                ></el-table-column>
                <el-table-column
                  prop="map.userInfo.nick_name"
                  align="center"
                  label="用户"
                ></el-table-column>
                <el-table-column
                  prop="create_time"
                  align="center"
                  label="下单时间"
                ></el-table-column>
                <el-table-column
                  prop="rcharge_abstract"
                  align="center"
                  label="描述"
                ></el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" style="color: #23c3c4"
                      >待审核</span
                    >
                    <span
                      v-else-if="scope.row.status == 2"
                      style="color: #b8b8b8"
                      >已通过</span
                    >
                    <span
                      v-else-if="scope.row.status == 3"
                      style="color: #ff6262"
                      >已拒绝</span
                    >
                    <span
                      v-else-if="scope.row.status == 4"
                      style="color: #ff6262"
                      >注销</span
                    >
                    <span v-else style="color: #ff6262">冻结</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="categories"
                  align="center"
                  label="参加活动"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.commodity_type == 'A卡'"
                      @click="changeadd(scope.row)"
                    >
                      参加活动
                    </el-button>
                    <div v-else>无</div>
                  </template>
                </el-table-column>
              </el-table>
              <!--分页-->
              <div class="fyb">
                <el-pagination
                  background
                  @current-change="orderCurrentChange"
                  :current-page.sync="ocurrentPage"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="ototal"
                ></el-pagination>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="购物券" name="2"> </el-tab-pane> -->
        <el-tab-pane label="活动列表" name="2">
          <div style="display: flex; margin-bottom: 20px; margin-top: 15px">
            <el-button type="warning" plain @click="AddActivity = true"
              >添加活动</el-button
            >
          </div>
          <el-table
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="Activearray"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
          >
          <el-table-column
              prop="id"
              align="center"
              label="id"
            ></el-table-column>
            <el-table-column
              prop="descr"
              align="center"
              label="活动"
            ></el-table-column>
            <el-table-column
              prop="coupon_time"
              label="领券张数"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="start_time"
              align="center"
              label="开始时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="end_time"
              label="结束时间"
            ></el-table-column>
            <el-table-column prop="firstNav" align="center" label="操作">
              <template slot-scope="scope">
                <div class="flex f-wrap">
                  <span
                  style="color: #fc7e24; margin-right: 10px"
                  @click="updateInfo(scope.row)"
                  >编辑</span
                >
                  <span
                  style="color: #fc7e24; margin-right: 10px"
                  v-if="scope.row.status == 3"
                  @click="Movabledismounting(scope.row.id,2)"
                  >上架</span
                >

                <span
                  style="color: #333; cursor: pointer"
                  v-if="scope.row.status == 2"
                  @click="Movabledismounting(scope.row.id,3)"
                  >下架</span
                >
                </div>
              </template>
            </el-table-column>
          </el-table>
           <paging-fy
          @currentPageChange="currentPageChange"
          :currentPage="ActivearrayPage"
          :total="Activearraytotal"
        ></paging-fy>
        </el-tab-pane>
      </el-tabs>

      <!-- 在售商品 -->
      <div class="PERInformation-table">
        <!-- 添加表单弹窗 -->
        <!-- dialog 添加商品弹框-->
        <div>
          <el-dialog
            title="添加商品"
            :width="ks ? '95%' : '50%'"
            :visible.sync="dialogFormVisible"
          >
            <el-form :model="OfficialCourses">
              <el-form-item label="商品名称" :label-width="formLabelWidth">
                <el-input
                  v-model="OfficialCourses.title"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="商品图片" :label-width="formLabelWidth">
                <avatar-uploader
                  @getImgUrl="Uploadcourintroductioncover4"
                  :clear="true"
                  :url="OfficialCourses.face_url"
                ></avatar-uploader>
              </el-form-item>

              <el-form-item label="商品成本价" :label-width="formLabelWidth">
                <el-input
                  v-model="OfficialCourses.amout"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="商品价格" :label-width="formLabelWidth">
                <el-input
                  v-model="OfficialCourses.discount_amout"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="商品简介" :label-width="formLabelWidth">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="OfficialCourses.introduction_content"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="详情图片" :label-width="formLabelWidth">
                <div style="display: flex">
                  <avatar-uploader
                    @getImgUrl="Uploadimg"
                    :clear="true"
                    :url="ImgList[0]"
                  ></avatar-uploader>
                  <avatar-uploader
                    @getImgUrl="Uploadimga"
                    :clear="true"
                    :url="ImgList[1]"
                  ></avatar-uploader>
                  <avatar-uploader
                    @getImgUrl="Uploadimgb"
                    :clear="true"
                    :url="ImgList[2]"
                  ></avatar-uploader>
                </div>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="OfficialCoursesChange"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>

        <div style="white-space: pre-line"></div>
        <el-dialog title="参加活动" :visible.sync="YNaddActive">
          <el-select
            v-model="changeaddzz"
            placeholder="请选择活动"
            value-key="id"
          >
            <el-option
              v-for="(item, index) in ActivityList"
              :key="index"
              :label="item.descr"
              :value="item"
            ></el-option>
          </el-select>
          <span slot="footer" class="dialog-footer">
            <el-button @click="YNaddActive = false">取 消</el-button>
            <el-button type="primary" @click="Participation">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="参加活动" :visible.sync="YNaddActive">
          <el-select
            v-model="changeaddzz"
            placeholder="请选择活动"
            value-key="id"
          >
            <el-option
              v-for="(item, index) in ActivityList"
              :key="index"
              :label="item.descr"
              :value="item"
            ></el-option>
          </el-select>
          <span slot="footer" class="dialog-footer">
            <el-button @click="YNaddActive = false">取 消</el-button>
            <el-button type="primary" @click="Participation">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          class="dialog"
          title="新增活动"
          :visible.sync="AddActivity"
          :center="true"
          width="850px"
        >
          <el-form
            id="dialogfrom"
            ref="form"
            :model="formcon"
            class="dialogfrom"
            label-width="150px"
            :inline="true"
            label-position="right"
          >
            <div>
              <el-form-item
                label="活动类型"
                :required="true"
                :hide-required-asterisk="true"
              >
                <el-select v-model="region" placeholder="请选择活动类型">
                  <el-option
                    v-for="item in formlist"
                    :key="item.type"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="活动标题"
              >
                <el-input v-model="formcon.descr"></el-input>
              </el-form-item>
              <el-form-item label="券id" style="margin-bottom: 30px"  :required="true">
                <el-input
                  v-model="formcon.pay_deposit"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="可用券数"
              >
                <el-input v-model="formcon.coupontime"></el-input>
              </el-form-item>
              <!-- 
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="成团人数"
              >
                <el-input v-model="formcon.number_of_people"></el-input>
              </el-form-item> -->
              <!--               
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="佣金数"
              >
                <el-input v-model="formcon.each_time_percentage"></el-input>
              </el-form-item> -->

              <el-form-item
                :required="true"
                label="活动开始时间"
                style="margin-bottom: 30px"
              >
                <el-date-picker
                  v-model="formcon.start_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                :required="true"
                label="活动结束时间"
                style="margin-bottom: 30px"
              >
                <el-date-picker
                  v-model="formcon.end_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item style="margin-bottom: 30px" label="页面地址">
                <el-input v-model="formcon.url"></el-input>
              </el-form-item>
              <el-form-item label="活动海报" style="margin-bottom: 30px">
                <avatar-uploader @getImgUrl="Uploadbackgroundimage">
                  <!-- :clear="if_clear"
                  :url="background_image" -->
                </avatar-uploader>
              </el-form-item>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="AddActivity = false">取 消</el-button>
            <el-button type="primary" class="butt" @click="changetrue"
              >确 定</el-button
            >
          </span>
        </el-dialog>

        <el-dialog
          class="dialog"
          title="修改活动"
          :visible.sync="updateActivity"
          :center="true"
          width="850px"
        >
          <el-form
            id="dialogfrom"
            ref="form"
            :model="updateForm"
            class="dialogfrom"
            label-width="150px"
            :inline="true"
            label-position="right"
          >
            <div>
              <el-form-item
                label="活动类型"
                :required="true"
                :hide-required-asterisk="true"
              >
                <el-select v-model="updateForm.type" placeholder="请选择活动类型">
                  <el-option
                    v-for="item in formlist"
                    :key="item.type"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="活动标题"
              >
                <el-input v-model="updateForm.descr"></el-input>
              </el-form-item>
              <el-form-item label="券id" style="margin-bottom: 30px"  :required="true">
                <el-input
                  v-model="updateForm.pay_deposit"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="margin-bottom: 30px"
                :required="true"
                :hide-required-asterisk="true"
                label="可用券数"
              >
                <el-input v-model="updateForm.coupon_time"></el-input>
              </el-form-item>
              <el-form-item
                :required="true"
                label="活动开始时间"
                style="margin-bottom: 30px"
              >
                <el-date-picker
                  v-model="updateForm.start_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                :required="true"
                label="活动结束时间"
                style="margin-bottom: 30px"
              >
                <el-date-picker
                  v-model="updateForm.end_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item style="margin-bottom: 30px" label="页面地址">
                <el-input v-model="updateForm.url"></el-input>
              </el-form-item>
              <el-form-item label="活动海报" style="margin-bottom: 30px">
                <avatar-uploader @getImgUrl="Uploadbackgroundimage2" :url="updateForm.background_image">
                  <!-- :clear="if_clear"
                   -->
                </avatar-uploader>
              </el-form-item>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="updateActivity = false">取 消</el-button>
            <el-button type="primary" class="butt" @click="updateActivityInfo"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// import Location from "@/js/city.js";
import mapdemo from "@/components/map.vue";
export default {
  name: "MerchantDetails",
  components: {
    mapdemo: mapdemo,
  },
  data() {
    return {
      // timedata: { timek: "", timej: "" },
      // AddActivityParameters: {},
      formlist: [],
      region: "",
      formcon: {
        coupontime: "", //券数
        descr: "", //活动标题
        number_of_people: "1", //成团人数
        each_time_percentage: "1", //佣金数
        start_time: "", //开始时间
        end_time: "", //结束时间
        url: "", //活动地址
        separate_amount: "", //单独购买
        amount: "1", //原价
        discount_amount: "1", //活动价格
        coupon_time: "1", //科目数
        sessions: "1", //单科节数
        add_amount: "1", //增加一科金额
        activity_description: "1", //文字简
        yajin: "", //押金
        ms: false, //秒杀
        msztdata: [
          { name: "未开始", value: "1" },
          { name: "开始", value: "2" },
          { name: "结束", value: "3" },
          { name: "未开启", value: "4" },
        ], //秒杀状态
        mszt: "", //秒杀内容
        activity_starttime: "", //秒杀开始时间
        activity_endtime: "", //秒杀结束时间
      },
      Pictureintroduction: "", // 图片介绍
      poster: "", //海报

      ks: false,
      AddActivity: false,
      nav: {
        firstNav: "商户管理",
        secondNav: "商户详情",
      },
      Commodityquery: "",
      activeName: 0,
      info: {},
      Location,
      dialogmap: false,
      id:{},
      idcategories_child: "",
      idcategories: "",
      setadders: false,
      piclist: [],
      ActivityList: [],
      // 选中的
      value: "",
      // 添加商品
      // 图片上传
      activeImg: -1,
      dialogImageUrl: "",
      ImgList: [],
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      // 弹框表单
      labelPosition: "right",
      recording: [], //商品数据
      teacherData: [], //教师数据
      ListPageChild: [],
      ListPageChild_child: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      total2: 0,
      // 添加表单弹窗
      YNaddActive: false,
      changeaddzz: "",
      addActiveData: {},
      dialogFormVisible1: false, //修改资料
      dialogFormVisible: false, //商品类型1
      dialogFormVisible2: false, //商品类型2
      dialog2: false, //添加老师
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      // 选中的
      OfficialCourses: {}, //正式商品
      AddTeacher: {}, //添加老师，
      orderList: [],
      ocurrentPage: 1,
      ototal: 0,
      curuser: {},
      selected_user: "",
      userList: [],
      hasbind:false,
      Activearray: [],
      Activearraytotal: 0,
      ActivearrayPage:1,
      updateActivity:false,
      updateForm:{},
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList:[],

    };
  },
  mounted() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
    this.getMerchemInfo()
    this.ks = window.screen.width < 768;
    // this.id.categories = this.id.categories.split("/");
    // this.id.categories_child = this.id.categories_child.split("/");
    this.handleCurrentChange(1);
    this.gettoken(); // 请求token
    this.GetListPageChild();
    this.GetListPageChild_child();
    
    this.GetActivityList();
    // this.getInfo();
    this.getOrder();
    this.GetList1();
    this.getTypeOfActivity()
  },
  methods: {
    getMerchemInfo(){
      let url = "/user/mastermechanism/findById";
      this.$axios.get(url,{params:{id: JSON.parse(this.$route.query.id)}}).then((res) => {
        // console.log(res);
        this.id = res.data.data;
        this.piclist = this.id.introduction_pic.split(",");
    this.idcategories_child = this.id.categories_child;
    this.idcategories = this.id.categories;
    this.cityList.push(this.id.province)
    this.cityList.push(this.id.city)
    this.cityList.push(this.id.district)
      });
    },
    updateInfo(row){
      this.updateForm = row,
      this.updateActivity = true
    },
    getTypeOfActivity() {
      let url =
        "/user/businessActivityType/queryNoRepeat";
      this.$axios.get(url).then((res) => {
        // console.log(res);
        this.formlist = res.data.data;
      });
    },
    currentPageChange(v){
    this.ActivearrayPage=v
    },
    // 活动下架
    Movabledismounting(id) {
      let url = "/user/businessActivityType/update";
      let data = {
        id: id,
        status: 4,
      };
      this.$axios.post(url, data).then((res) => {
        this.$message({ type: "success", message: res.data.message });
        this.GetList1();
      });
    },
    // 活动列表
    GetList1() {
      this.$axios({
        method: "get",
        url: "/user/businessActivityType/queryManagerListPage",
        params: {
          pageSize: 10,
          currentPage: this.ActivearrayPage,
          entry_mechanism_ids: this.id.id, //机构id
          status: 2,
        },
      })
        .then((res) => {
          this.Activearray = res.data.data.rows;
          this.Activearraytotal = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changetrue() {
      let data = {
        entry_mechanism_ids: this.id.id, //机构id
        coupon_time: this.formcon.coupontime, //券的数量
        pay_deposit: this.formcon.pay_deposit,//券id
        type: this.region,
        status: 2,
        name:  this.formcon.descr,
        spike_status: this.formcon.mszt,
        // title: this.formcon.name,
        descr: this.formcon.descr,
        end_time: this.formcon.end_time,
        start_time: this.formcon.start_time,
        activity_endtime: this.formcon.activity_endtime,
        activity_starttime: this.formcon.activity_starttime,
        is_new_customers: true,
        separate_amount: this.formcon.separate_amount,
        amount: this.formcon.amount,
        discount_amount: this.formcon.discount_amount,
        activity_description: this.formcon.activity_description,
        sessions: this.formcon.sessions,
        coupon_time: this.formcon.coupon_time,
        choose_num: this.formcon.coupon_time,
        pay_amount: this.formcon.discount_amount,
        coupon_time: this.formcon.add_amount,
        number_of_people: this.formcon.number_of_people,
        each_time_percentage: this.formcon.each_time_percentage,
        image_description: this.Pictureintroduction, //图片简介
        // background_image: this.background_image,
        background_image: this.poster, //海报图片
        url: this.formcon.url,
        // logo: this.imageUrl4, //封面
        // image_description: this.imageUrl3, //图片简介
        // gift_image: this.imageUrl2, //礼物图片
        // sponsorship_image: this.imageUrl1, //赞助图片
      };

      this.$axios
        .post("/user/businessActivityType/insert", data)
        .then((res) => {
          if (res.data.message == "成功") {
            this.$message({ type: "success", message: res.data.message });
            this.GetList1();
            this.AddActivity = false;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    updateActivityInfo() {
      let data = {
        id:this.updateForm.id,
        coupon_time: this.updateForm.coupon_time, //券的数量
        descr: this.updateForm.descr, 
        pay_deposit: this.updateForm.pay_deposit, 
        start_time: this.updateForm.start_time, 
        end_time: this.updateForm.end_time, 
        url: this.updateForm.url, 
        coupon_time: this.updateForm.coupon_time, 
      };
      this.$axios
        .post("/user/businessActivityType/update", data)
        .then((res) => {
          if (res.data.message == "成功") {
            this.$message({ type: "success", message: res.data.message });
            this.GetList1();
            this.updateActivity = false;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    // 图片介绍
    Uploadcourintroductioncover3(e) {
      this.Pictureintroduction = e;
    },
    // 海报
    Uploadbackgroundimage(e) {
      this.poster = e;
    },
    tabclick() {},
    // 商品查询
    search() {
      // Commodityquery
      this.$axios({
        method: "get",
        url: "/user/masterSetPrice/queryManagerListPage",
        params: {
          pageSize: 10,
          currentPage: this.currentPage,
          // is_support_teach_paypal: true,
          mechanism_id: this.id.id,
          title: this.Commodityquery,
        },
      }).then((res) => {
        this.recording = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },

    changeuser(e) {
      this.curuser = e;
      if(this.curuser.merchant_id){
        if(this.curuser.merchant_id!=0 || this.curuser.mechanism_id!=0){
          this.$message({message:'该用户已存在绑定的机构'})
          this.hasbind=true
        }  
      }
      this.hasbind=false
    },
    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/userInfo/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              mobile: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },

    orderCurrentChange(p) {
      this.ocurrentPage = p;
      this.getOrder();
    },
    getOrder() {
      var url = "/user/rechargerecord/queryManagerListPage";
      let params = {
        mechanism_id: this.id.id,
        rcharge_type: "shop",
        is_teach_paypal: true,
        pageSize: 10,
        currentPage: this.ocurrentPage,
        is_robot: true,
      };
      this.$axios({ url, params }).then((res) => {
        this.orderList = res.data.data.rows;
        this.ototal = res.data.data.total;
      });
    },
    editInfo() {
      this.dialogFormVisible1 = true;
      // this.getInfo();
    },
    getInfo() {
      var url =
        "/user/mastermechanism/queryByMessage?type="+this.id.type+"&mechanism_name=" +
        this.id.mechanism_name +
        "&mechanism_telephone=" +
        this.id.mechanism_telephone;
      this.$axios.get(url).then((res) => {
        console.log(res);
        this.info = res.data.data.rows[0];
        this.piclist = this.info.introduction_pic && this.info.introduction_pic.split(",");
        this.info.categories = this.info.categories.split(",");
        this.info.categories_child = this.info.categories_child.split(",");
      });
    },
    // 修改资料//修改商户信息
    add() {
      // if (this.curuser.merchant_id) {
      //   if (this.curuser.merchant_id != 0 || this.curuser.mechanism_id != 0) {
      //     this.$message({ message: "该用户已存在绑定的机构" });
      //     return;
      //   }
      // }
      this.dialogFormVisible1 = false;
      let data = {
        id: this.info.id,
        contact_telephone: this.info.contact_telephone,
        contact_information: this.info.contact_information,
        contacts: this.info.contacts,
        contacts_title: this.info.contacts_title,
        introduction_content: this.info.introduction_content,
        status: "2",
        introduction_pic: this.piclist.toString(),
        latitude: this.info.latitude,
        longitude: this.info.longitude,
        mechanism_addr: this.info.mechanism_addr,
        mechanism_advantage: this.info.mechanism_advantage,
        mechanism_logo: this.info.mechanism_logo,
        mechanism_name: this.info.mechanism_name,
        mechanism_telephone: this.info.mechanism_telephone,
        support_means: this.info.support_means,
        restrictions_count: this.info.restrictions_count,
        type:this.info.type,
        categories: this.info.categories.join(","),
        categories_child: this.info.categories_child.join(","),
        payee_logon_id: this.info.payee_logon_id,
        is_support_teach_paypal: this.info.is_support_teach_paypal,
        facade_view: this.info.facade_view,
        // user_id: this.curuser.user_id || 0,
        province: this.cityList[0],
        city: this.cityList[1],
        district: this.cityList[2],
      };
      this.$axios({
        method: "post",
        url: "/user/mastermechanism/update",
        data: data,
      })
        .then((res) => {
          if (res.data.code == 0) {
            //成功
            this.$message({
              message: res.data.message,
              type: "success",
            });
          }
          this.getMerchemInfo()
        })
        .catch((error) => {});
    },

    GetListPageChild() {
      let url =
        "/user/mechanismCategory/queryListPageChild?type=1&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild = res.data.data;
      });
    },
    GetListPageChild_child() {
      let url =
        "/user/mechanismCategory/queryListPageChild?type=2&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild_child = res.data.data;
      });
    },
    //添加商品
    OfficialCoursesChange() {
      let data = {
        ...this.OfficialCourses,
        course_num: "1",
        introduction_url: this.ImgList.toString(),
        mechanism_id: this.id.id,
        status: 2,
        type: "mechanism_offline",
        commodity_type: "商品",
      };
      let url = "/user/masterSetPrice/insert";
      this.$axios.post(url, data).then((res) => {
        this.GetList();
        this.$message(res.data.message);
      });
      this.dialogFormVisible = false;
    },
    //添加商户老师
    AddTeacherChange() {
      let sex;
      if (this.AddTeacher.sex == "男") {
        sex = 1;
      } else {
        sex = 2;
      }
      let url = "/user/user/insertMechanismMaster";
      let data = {
        master_age: this.AddTeacher.master_age,
        login_pass: this.AddTeacher.login_pass,
        login_name: this.AddTeacher.login_name,
        nick_name: this.AddTeacher.nick_name,
        register_platform: "teach_paypal",
        mechanism_id: this.id.id,
        avatar: this.AddTeacher.avatar,
        mobile: this.AddTeacher.mobile,
        sex: sex,
        specialty: this.AddTeacher.specialty,
        categories_child: this.AddTeacher.categories_child,
        introduction_content: this.AddTeacher.introduction_content,
      };
      this.$axios.post(url, data).then((res) => {
        this.dialog2 = !this.dialog2;
        this.GetTeacherList();
      });
    },
    getmapdata(data) {
      //经纬度
      this.dialogmap = !this.dialogmap;
      this.id.longitude = data.addrPoint.lng;
      this.id.latitude = data.addrPoint.lat;
      this.id.mechanism_addr =
        data.province + data.city + data.district + data.address;
    },
    getmapdata2(data) {
      //经纬度
      this.dialogmap = !this.dialogmap;
      this.info.longitude = data.addrPoint.lng;
      this.info.latitude = data.addrPoint.lat;
      this.info.mechanism_addr = data.address;
      this.info.province = data.province;
      this.info.city = data.city;
      this.info.district = data.district;
    },
    Participation() {
      let url = "/user/businessActivityType/update",
        data = {
          id: this.changeaddzz.id,
          entry_mastersetprice_ids: this.addActiveData.id,
        };
      this.$axios.post(url, data).then((res) => {
        alert("参加");
      });
    },
    GetActivityList() {
      this.$axios
        .get(
          "/user/businessActivityType/queryManagerListPage?status=2&pageSize=20"
        )
        .then((res) => {
          this.ActivityList = res.data.data.rows;
        });
    },

    Uploadimg(file) {
      this.ImgList[0] = file;
    },
    Uploadimga(file) {
      this.ImgList[1] = file;
    },
    Uploadimgb(file) {
      this.ImgList[2] = file;
    },
    Uploadimg2(file) {
      this.piclist[0] = file;
    },
    Uploadimg21(file) {
      this.piclist[1] = file;
    },
    Uploadimg22(file) {
      this.piclist[2] = file;
    },
    SetupImg(index) {
      this.activeImg = index;
    },
    deleteimg(index) {
      this.piclist.splice(index, 1);
    },
    changeadd(row) {
      this.addActiveData = row;
      this.YNaddActive = !this.YNaddActive;
    },
    //图片操作
    beforeUploadGetKeys(file) {
      this.gettoken();
    },
    // 商户logo图片
    Uploadcourintroductioncover(file) {
      this.info.mechanism_logo = file;
    },
    // 商户营业执照图片
    Uploadcourintroductioncover1(file) {
      this.info.support_means = file;
    },
    // 商户门面图片
    Uploadcourintroductioncover2(file) {
      this.info.facade_view = file;
    },
    // 商户其他图片
    Uploadcourintroductioncover30(file) {
      this.piclist[0] = file;
    },
    Uploadcourintroductioncover31(file) {
      this.piclist[1] = file;
    },
    Uploadcourintroductioncover32(file) {
      this.piclist[2] = file;
    },
    Uploadcourintroductioncover33(file) {
      this.piclist[3] = file;
    },
    Uploadcourintroductioncover34(file) {
      this.piclist[4] = file;
    },
    //添加正式商品封面
    Uploadcourintroductioncover4(file) {
      this.OfficialCourses.face_url = file;
    },
    Uploadcourintroductioncover5(file) {
      this.OfficialCourses.introduction_url = file;
    },
    Uploadbackgroundimage2(file) {
      this.updateForm.background_image = file;
    },

    //添加老师证件照
    Uploadcourintroductioncover6(file) {
      this.AddTeacher.avatar = file;
    },
    // 获取token
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {});
    },

    // list表单

    filterChange(filterObj) {},

    //商品列表
    GetList() {
      this.$axios({
        method: "get",
        url: "/user/masterSetPrice/queryManagerListPage",
        params: {
          pageSize: 10,
          currentPage: this.currentPage,
          // is_support_teach_paypal: true,
          mechanism_id: JSON.parse(this.$route.query.id)
        },
      }).then((res) => {
        this.recording = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },

    //商户老师列表
    GetTeacherList() {
      let url = "/user/masterInfo/queryManagerListPage";
      let params = {
        type: "teach_paypal",
        pageSize: "10",
        currentPage: "1",
        mechanism_id: this.id.id,
      };
      this.$axios({ method: "get", url, params }).then((res) => {
        this.teacherData = res.data.data.rows;
        this.total2 = res.data.data.total;
      });
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
      this.GetTeacherList();
    },

    //上架
    Uploaded(row) {
      let url = "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        status: 2,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已上架");
        this.GetList();
      });
    },
    //下架
    UnUploaded(row) {
      let url = "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        status: 4,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已下架");
        this.GetList();
      });
    },
    //推荐
    tuijian(row) {
      let url = "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        is_recommend: true,
      };
      this.$axios.post(url, data).then((res) => {
        alert("推荐");
        this.GetList();
      });
    },
    //取消推荐
    Untuijian(row) {
      let url = "/user/masterSetPrice/update";
      let data = {
        id: row.id,
        is_recommend: false,
      };
      this.$axios.post(url, data).then((res) => {
        alert("取消推荐");
        this.GetList();
      });
    },
    //修改
    handleClick(row, Identity) {
      this.$router.push({
        name: "MerchantCourse",
        query: {
          id: JSON.stringify(row),
          Identity,
          Institutions: JSON.stringify(this.id),
        },
      });
    },
    //离职
    Separation(row) {
      let url = "/user/masterInfo/update";
      let data = {
        id: row.id,
        status: 4,
      };
      this.$axios.post(url, data).then((res) => {
        alert("已离职");
        this.GetTeacherList();
      });
    },
  },
};
</script>
<style scoped lang="less">
.to-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
  .left {
    text-align: center;
    margin-right: 20px;
    @media screen and(max-width: 768px) {
      width: 100%;
    }
  }
  .dd1 {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .right {
      width: 300px;
      height: 50px;
      @media screen and(max-width: 768px) {
        width: 100%;
        height: auto;
      }
      li {
        padding-right: 20px;
        margin-bottom: 10px;
        color: #434343;
        > span {
          color: #b5b5b5;
        }
        @media screen and(max-width: 768px) {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
    .blt {
      width: 100px;
      height: 40px;
      margin-right: 100px;
    }
  }
}
.picture {
  display: flex;
  flex-wrap: wrap;
  .picture-li {
    > h2 {
      margin-bottom: 10px;
    }
    width: 15%;
    @media screen and(max-width:768px) {
      width: 100%;
    }
  }
}
/* 在售商品 */
.PERInformation-table {
  position: relative;
  margin-top: 50px;
}

.PERInformation-table h4 {
  text-align: center;
}
</style>
